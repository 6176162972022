.cdn-shadow {
    -webkit-box-shadow: 0px 0px 10px 4px rgb(235 241 251);
    -moz-box-shadow: 0px 0px 10px 4px rgb(235 241 251);
    box-shadow: 0px 0px 10px 4px rgb(235 241 251);
}

.form-label {
    font-size: 0.9rem;
    font-weight: 500;
    color: #4a5568;
}

.form-control {
    height: 2.5rem;
    border-radius: 0.375rem;
    border: 1px solid #e2e8f0;
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: #4a5568;
}

.btn {
    height: 2.5rem;
    border-radius: 0.375rem;
}

.cdn-sidebar{
    width: 10%;
    background-color: #fff;
    min-height: 100vh;
    border-radius: 0.375rem 0 0 0.375rem;
}

.cdn-main{
    width: 90%;
    background-color: #eaf5ff;
    min-height: 100vh;
}

.loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4a556888;
}

.fs-10{
    font-size: 0.9rem;
}
.fs-8{
    font-size: 0.8rem;
}


.custom-dropdown-toggle::after {
    content: none;
}
  
  
  
  
  

