.cdn-menu-item{
    padding: 0.5rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: #4a5568;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid #e2e8f0;
}
.cdn-menu-item:hover{
    background-color: #e2e8f0;
}