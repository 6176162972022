.table-row-tr{
    border: 1px solid #f1f1f1;
    border-radius: 0.375rem;
}

.td-date{
    font-size: 0.8rem;
}

.td-sno {
    font-size: 0.8rem;
    font-weight: 500;
}

.td-monospace {
    font-family: monospace;
}